import { useEffect, useState } from 'react';
import debug from 'debug';

import { AppProvider } from './context/AppContext';
import { UserProvider, useUserState } from './services/user/UserContext';
import { UserServiceProvider, useUserService } from './services/user/UserServiceContext';
import { BrowserRouter, Outlet, Routes, Route, Link, useLocation } from 'react-router-dom';
import { NavBar } from './components/nav-bar/NavBar';
import { HomePage } from './pages/home/HomePage';
import { LoginPage } from './pages/login/LoginPage';
import { LogoutPage } from './pages/login/LogoutPage';
import { AdminPage } from './pages/admin/AdminPage';
import { ProfilePage } from './pages/profile/ProfilePage';
import { AccountPage } from './pages/account/AccountPage';
import { MarketplacePage } from './pages/marketplace/MarketplacePage';
import { MySingleMomentPage } from './pages/profile/MySingleMomentPage';
import { MarketplaceSinglePage } from './pages/marketplace/MarketplaceSinglePage';
import { PacksPage } from './pages/packs/PacksPage';
import { MySinglePackPage } from './pages/profile/MySinglePackPage';
import { RevealPackPage } from './pages/profile/RevealPackPage';
import { PackSinglePage } from './pages/packs/PackSinglePage';
import { HelpPage } from './pages/help/HelpPage';
import { CommunityPage } from './pages/community/CommunityPage';
import { Alert } from './components/alerts/Alert';
import { UserAccount } from './common/UserAccount';
import { UserPermission } from './common/UserPermission';
import { environment } from './environments/environment';
import versionFile from './version.json';

import './App.css';

const versionToken = 'VERSION';
const log = debug('app:app');

window.debugApp = debug;
window['log'] = debug('app:misc');

if (environment.production) {
  debug.disable();
} else {
  debug.enable('app:*');
}

function hasAdminPerm(userState: UserAccount) {
  return userState.permissions.indexOf(UserPermission.Admin) !== -1;
}

function MainFooter() {
  const userAccount = useUserState();
  return (
    <footer className="main-footer">
      &copy; 2021 | <Link to="/account">Account</Link>
      {hasAdminPerm(userAccount) && (<div>
        <Link to="/admin">Admin</Link>
      </div>)}
    </footer>
  )
}

function LinkWarning({ initialized }: { initialized: boolean }) {
  const userService = useUserService();
  const userAccount = useUserState();
  const location = useLocation();

  const [showLinkWarning, setShowLinkWarning] = useState(false);

  useEffect(() => {
    if (!initialized
      || !userAccount
      || !userAccount.loggedIn
      || location.pathname === '/account'
    ) {
      setShowLinkWarning(false);
      return;
    }

    if (!userAccount.checked) {
      setShowLinkWarning(false);
      userService.checkIfLinked();
      return;
    }

    setShowLinkWarning(!userAccount.linked);
  }, [userAccount, initialized, location]);

  if (!showLinkWarning) {
    return null;
  }

  return (
    <div className="container pt-2">
      <Alert.Warning>
        Account not linked yet. Go to <Link to="/account">Account page</Link> to get started!
      </Alert.Warning>
    </div>
  );
}

export const ModalRootId = 'modal-root';

function Layout() {
  const userService = useUserService();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    userService.waitForInit().then(() => {
      setInitialized(true);
    });
  }, []);

  return (
    <div>
      <NavBar />
      <main>
        <LinkWarning initialized={initialized} />
        {initialized ? (
          <Outlet />
        ) : (
          <div className="container py-2">
            <Alert.Info>Loading...</Alert.Info>
          </div>
        )}
      </main>
      <MainFooter />
      <div id={ ModalRootId } />
    </div>
  );
}

function Providers({ children }) {
  return (
    <AppProvider>
      <UserProvider>
        <UserServiceProvider>
          {children}
        </UserServiceProvider>
      </UserProvider>
    </AppProvider>
  );
}

function App() {
  const basename = environment.routerBasename?.replace(versionToken, versionFile.version) ?? undefined;
  return (
    <Providers>
      <BrowserRouter basename={basename}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="index.html" element={<HomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="logout" element={<LogoutPage />} />
            <Route path="admin" element={<AdminPage />} />
            <Route path="profile">
              <Route index element={<ProfilePage />} />
              <Route path=":profileId">
                <Route index element={<ProfilePage moments />} />
                <Route path="moments">
                  <Route index element={<ProfilePage moments />} />
                  <Route path=":nftId" element={<MySingleMomentPage />} />
                </Route>
                <Route path="packs">
                  <Route index element={<ProfilePage packs />} />
                  <Route path=":packId">
                    <Route index element={<MySinglePackPage />} />
                    <Route path="reveal" element={<RevealPackPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="account" element={<AccountPage />} />
            <Route path="marketplace">
              <Route index element={<MarketplacePage />} />
              <Route path=":nftId" element={<MarketplaceSinglePage />} />
            </Route>
            <Route path="packs">
              <Route index element={<PacksPage />} />
              <Route path=":saleId" element={<PackSinglePage />} />
            </Route>
            <Route path="help" element={<HelpPage />} />
            <Route path="community" element={<CommunityPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Providers>
  );
}

export default App;
