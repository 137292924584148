import React, { useRef } from 'react';

import mockCommon from '../../components/nft-card/mockCommon.png';
import mockRare from '../../components/nft-card/mockRare.png';
import mockEpic from '../../components/nft-card/mockEpic.png';
import iconExpand from '../../assets/svg/moment-expand.svg';
import iconBack from '../../assets/svg/moment-back.svg';

import './Moment.scss';
import { Link } from 'react-router-dom';
import { ModalCloseButton, ModalPortal } from '../modalportal/ModalPortal';

function parseVideoUri(url: string) {
  if (url.startsWith('ipfs')) {
    const uri = url.split('://')[1];
    return `https://ipfs.io/ipfs/${uri}`;
  }

  return url;
}

const stopPropagation = (e: {stopPropagation: () => void}) => e.stopPropagation();

const SingleMomentExpanded = ({uri, onClose}: {uri: string, onClose: () => void }) => {
  const videoSrc = uri ? parseVideoUri(uri) : null;
  return <ModalPortal>
    <div className="single-moment-expanded" onClick={ onClose }>
      <ModalCloseButton />
      <video loop autoPlay controls muted preload="auto" onClick={ stopPropagation }>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  </ModalPortal>
}

function MomentVisuals(props: Pick< Play.NFTMetadata, 'id' | 'uri' | 'rarity'> & { paused: boolean}) {
  const [showVideo, setShowVideo] = React.useState(false);
  const videoEl = useRef<HTMLVideoElement>();

  React.useEffect(() => {
    setShowVideo(false);
    const timeout = setTimeout(() =>  setShowVideo(true), 500);

    return () => clearTimeout(timeout);
  }, [props.id]);

  if (videoEl.current && props.paused !== videoEl.current.paused) {
    if (props.paused) {
      videoEl.current.pause();
    } else {
      videoEl.current.play();
    }
  }

  const videoSrc = props.uri ? parseVideoUri(props.uri) : null;
  const rarity = props.rarity.toLowerCase();

  return (
    <div className="moment-visuals d-flex justify-content-center align-items-center">
      {showVideo && videoSrc ? (
        <video loop autoPlay controls muted preload="auto" ref={ videoEl }>
          <source src={videoSrc} type="video/mp4" />
        </video>
      ) : (
        <img src={rarity === 'common' ? mockCommon : rarity === 'rare' ? mockRare : mockEpic} />
      )}
    </div>
  );
}

const tags: { key: string, value: string }[] = [
  { key: 'Season', value: 'Season One' },
  { key: 'Episode', value: 'Episode 1' },
  { key: 'Collection', value: 'Mastery: Flirting' },
  { key: 'Location', value: 'Mastery: Flirting' },
  { key: 'Tone Tags', value: 'Funny, Cringe' },
  { key: 'Game Tags', value: 'Choice, Dialogue' },
]

type MomentProps = {
  metadata: Play.NFTMetadata,
  backText: string,
  backURL: string,
  children?: React.ReactNode
};

export const Moment = ({metadata, backText, backURL, children}: MomentProps) => {
  const [expand, setExpand] = React.useState(false);
  return <div className="d-flex flex-column single-moment card">
    <div className="d-flex justify-content-between align-items-center">
      <Link className="single-moment-back d-flex flex-row" to={ backURL }>
        <img src={iconBack} />
        <div className="single-moment-back-text"> { backText } </div>
      </Link>
      <div className="d-flex justify-content-end m-4">
        <button className="btn btn-secondary">
            Previous
        </button>
        <button className="btn btn-secondary">
            Next
        </button>
      </div>
    </div>

    <div className="single-moment-card-main-row d-flex flex-row justify-content-between">

      <div className="d-flex flex-row align-items-end">
        <MomentVisuals id={metadata.id} uri={metadata.uri} rarity={metadata.rarity} paused={ expand }/>
        { expand && <SingleMomentExpanded uri={metadata.uri} onClose={() => { setExpand(false) } }/> }
        <div>
          <img className="single-moment-expand" src={iconExpand} onClick={() => setExpand(true)}/>
        </div>
      </div>

      <div>

        <div className="moment-text d-flex flex-column">
          <div>
            <div className="d-flex justify-content-between">
              <h2>Moment Name</h2>
              <span className="single-moment-text-available">Available</span>
            </div>
            <h1>{metadata.name}</h1>
          </div>

          <div>
            <h4>Moment Type</h4>
            <h3>{metadata.rarity} &nbsp;# 3 / 49</h3>
          </div>

          { children }

          <div className="moment-tags d-flex flex-wrap justify-content-between">
            {tags.map(tag => (
              <div className="moment-tag" key={tag.key} >
                <h5>{tag.key}</h5>
                <p>{tag.value}</p>
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  </div>
}
